<template>
  <div class="home">
    <Hero />
    <AppMainPrize />
    <Prizes />
    <Steps />
    <Rating />
    <Products />
    <Streams />
    <Winners />
    <Faq />
  </div>
</template>

<script>
// @ is an alias to /src
import Hero from "@/components/AppHero.vue";
import Steps from "@/components/AppSteps.vue";
import AppMainPrize from "../components/AppMainPrize.vue";
import Prizes from "@/components/AppPrizes.vue";
import Products from "@/components/AppProducts.vue";
import Streams from "@/components/AppStreams.vue";
import Faq from "@/components/AppFaq.vue";
import Winners from "../components/AppWinners.vue";
import Rating from "../components/AppRating.vue";

export default {
  name: "Home",
  components: {
    Hero,
    Steps,
    Prizes,
    Rating,
    Products,
    Streams,
    Winners,
    Faq,
    AppMainPrize,
  },
  data: function () {
    return {
      publicPath: process.env.BASE_URL,
      sectionObserver: null,
    };
  },
  mounted() {
    // this.observeSections();
    // this.$modal.show("enter_code", {
    //   id: 630,
    // });
    const t = this;
    let doGoal = true;
    this.$parent.showHeader = true;
    this.$parent.showFooter = true;
    window.addEventListener("scroll", function () {
      if (
        window.innerHeight + Math.round(window.scrollY) >=
        document.body.offsetHeight / 2
      ) {
        if (doGoal) {
          // t.gtmEvent("50", "scroll", "send");
          // t.gtmEvent("bottom_50", "do", "internal");
          // t.setMetrika('scroll50');
          // setTimeout(() => {
          //   document.getElementById('scroll50').click();
          // }, 2000);
          doGoal = false;
        }
      }
      if (
        window.innerHeight + Math.round(window.scrollY) >=
        document.body.offsetHeight
      ) {
        // t.gtmEvent("bottom", "do", "internal");
        // t.setMetrika('scroll100');
        // document.getElementById('scroll100').click();
        // t.gtmEvent("100", "scroll", "send");
      }
    });
    if (window.innerWidth >= 1024) {
      const sections = document.querySelectorAll(".section");
      const links = document.querySelectorAll("[data-menuanchor]");
      document.addEventListener("scroll", (e) => {
        sections.forEach((ha) => {
          const rect = ha.getBoundingClientRect();
          if (
            (rect.top > 0 && rect.top < 100) ||
            (rect.bottom > 0 && rect.bottom < 1000)
          ) {
            const location = window.location.toString().split("#")[0];
            history.replaceState(null, null, location + "#" + ha.id);
            links.forEach((element) => {
              element.classList.remove("active");
            });
            links.forEach((element) => {
              element.classList.remove("active");
            });
            if (document.querySelector(`[data-menuanchor='${ha.id}']`)) {
              document
                .querySelector(`[data-menuanchor='${ha.id}']`)
                .classList.add("active");
              document.getElementById(ha.id).classList.add("active");
            }
          }
          // console.log(rect);
        });
      });
    }
    const params = this.$route.query;
    console.log(params);

    if (params.email === "0") {
      let socId = params.identity_vk || params.identity_ok || "";

      console.log(socId);

      if (socId.length < 1) {
        this.$modal.show("common_error", {
          title: "Что-то пошло не так!",
        });
        this.$router.push("/");
      } else {
        this.$store.commit("SIGNUP_SOC_ID", socId);
        this.$modal.show("soc_signup");
        this.$router.push({
          name: "Home",
        });
      }
    } else if (params.first === "1") {
      this.$store.commit("SET_TOKEN", params.auth_key);
      this.$store.dispatch("GetProfile").then((r) => {
        this.$modal.show("soc_signup-finish", {
          userName: r.data.first_name,
        });
        this.$router.push({
          name: "Home",
        });
      });
    } else if (params.result === "1") {
      this.$store.commit("SET_TOKEN", params.auth_key);

      this.$store.dispatch("GetProfile").then(() => {
        this.$router.push("/profile");
      });
    } else if (params.utm_source === "cpaex" && params.click_id) {
      localStorage.setItem("click_id", params.click_id);
      // this.$router.push({
      //   name: "Home",
      // });
    }
  },
  methods: {
    observeSections() {
      try {
        this.sectionObserver.disconnect();
      } catch (error) {
        console.log(error);
      }

      const options = {
        rootMargin: "0px 0px",
        threshold: 0,
      };
      this.sectionObserver = new IntersectionObserver(
        this.sectionObserverHandler,
        options
      );

      // Observe each section
      const sections = document.querySelectorAll(".section");
      sections.forEach((section) => {
        this.sectionObserver.observe(section);
      });
    },
    sectionObserverHandler(entries) {
      for (const entry of entries) {
        if (entry.isIntersecting) {
          const sectionId = entry.target.id;
          // Push sectionId to router here
          this.$router.replace({
            name: this.$route.name,
            hash: `#${sectionId}`,
          });
          console.log(sectionId);
          // this.$route.hash = `#${sectionId}`;

          console.log(this.$route.hash);
        }
      }
    },
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
  },
  created() {},
};
</script>
<style lang="scss"></style>
