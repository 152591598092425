<template>
  <div class="voting" v-if="vote_count" :key="index" :class="{ '_no-btns': noBtns, '_no-arrows': noArrows}">
    <div class="container _small-width">
      <div
        class="voting__title visible-xs"
        v-html="title || 'Голосуй <br />за любимого героя'"
      ></div>
      <div class="voting__text visible-xs" v-html="text"></div>
      <div class="voting__block">
        <div class="voting__col">
          <svg
            v-if="noArrows !== true"
            class="voting__arrows hidden-xs"
            width="137"
            height="60"
            viewBox="0 0 137 60"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M1 59L70 31L136 59" stroke="#FF9601" stroke-width="2" />
            <path
              opacity="0.3"
              d="M1 45L70 17L136 45"
              stroke="#FF9601"
              stroke-width="2"
            />
            <path
              opacity="0.1"
              d="M1 30L70 2L136 30"
              stroke="#FF9601"
              stroke-width="2"
            />
          </svg>

          <img
            src="../assets/images/voting/timaty.png"
            alt=""
            class="voting__img"
          />
          <button
            v-if="noBtns !== true"
            @click="setVote('timati')"
            class="voting__btn btn btn--yellow"
          >
            Голосовать
          </button>
        </div>
        <div class="voting__col">
          <div
            class="voting__title hidden-xs"
            v-html="title || 'Голосуй <br />за любимого героя'"
          ></div>
          <div class="voting__text hidden-xs" v-html="text"></div>
          <div class="voting__rating">
            <div class="voting__rating-counter">
              <small>ТИМАТИ</small>
              <span>{{ vote_count.timati }}</span>
            </div>
            <img
              src="../assets/images/voting/vs.svg"
              alt=""
              class="voting__rating-img hidden-xs"
            />
            <img
              src="../assets/images/voting/vs-m.png"
              alt=""
              class="voting__rating-img visible-xs"
            />
            <div class="voting__rating-counter hidden-xs">
              <small>парадеевич</small>
              <span>{{ vote_count.parad }}</span>
            </div>
          </div>
        </div>
        <div class="voting__col">
          <svg
            v-if="noArrows !== true"
            class="voting__arrows hidden-xs"
            width="137"
            height="60"
            viewBox="0 0 137 60"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M1 59L70 31L136 59" stroke="#FF9601" stroke-width="2" />
            <path
              opacity="0.3"
              d="M1 45L70 17L136 45"
              stroke="#FF9601"
              stroke-width="2"
            />
            <path
              opacity="0.1"
              d="M1 30L70 2L136 30"
              stroke="#FF9601"
              stroke-width="2"
            />
          </svg>
          <img
            src="../assets/images/voting/paradeevich.png"
            alt=""
            class="voting__img"
          />
          <button v-if="noBtns !== true" @click="setVote('parad')" class="voting__btn btn btn--yellow">
            Голосовать
          </button>
          <div class="voting__rating visible-xs">
            <div class="voting__rating-counter">
              <small>парадеевич</small>
              <span>{{ vote_count.parad }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: [String, Object]
    },
    text: {
      type: [String, Object]
    },
    noBtns: {
      default: false
    },
    noArrows: {
      default: false
    }
  },
  data: () => ({
    vote_count: null,
    index: 1,
  }),
  methods: {
    getData() {
      this.$store.dispatch("GetPromoDates").then((response) => {
        console.log(response);
        this.vote_count = response.vote_count;
        this.index++;
      });
    },
    setVote(vote) {
      if (this.user) {
        if (this.user.free_vote > 0) {
          this.$store
            .dispatch("SetVote", { vote })
            .then((response) => {
              this.$modal.show("common_success", {
                title: "Голос засчитан!",
                text: `Регистрируй больше чеков, чтоб голосовать за любимую команду`,
                checkButton: true,
              });
              this.getData();
            })
            .catch((e) => {
              const errors = Object.values(e.response.data);
              let list = [];
              for (let index = 0; index < errors.length; index++) {
                const element = errors[index];
                list.push(element);
              }
              this.$modal.show("common_error", {
                text: "Что-то пошло не так! ",
                list,
              });
            });
        } else {
          this.$modal.show("common_error", {
            title: "Недостаточно голосов",
            text: `Ты можешь получить дополнительные голоса, загрузив чеки`,
            checkButton: true,
          });
        }
      } else {
        this.$modal.show("authorization");
      }
    },
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style lang="scss" scoped>
.voting {
  position: relative;
  z-index: 3;
  &._in-profile {
    margin-top: rem(104px);
  }
  &.mt-0 {
    margin-top: 0;
  }
  @media (orientation: landscape) {
    &._in-profile {
      margin-top: rem(104px);
    }
    &.mt-0 {
      margin-top: 0;
    }
  }
  &__block {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: rem(0px) rem(22px) rem(18px);
    border: rem(2px) solid $orange;
    border-radius: rem(24px);
    @media (orientation: landscape) {
      height: rem(308px);
      padding: 0 rem(28px) rem(40px);
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
      border-radius: rem(48px);
      ._no-btns & {
        padding-bottom: rem(25px);
      }
    }
  }
  &__col {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    @media (max-width: $sm) {
      &:first-child {
        margin-top: rem(-53px);
      }
    }
    @media (orientation: landscape) {
      flex: auto;
    }
    &:hover {
      .voting__arrows {
        opacity: 1;
      }
    }
  }
  &__img {
    flex-grow: 0;
    width: 90%;
    margin-bottom: rem(-30px);
    ._no-btns & {
      margin-bottom: rem(30px);
    }
    @media (orientation: landscape) {
      width: rem(300px);
      ._no-btns & {
        margin-bottom: rem(-25px);
      }
    }
  }
  &__title {
    margin-bottom: rem(10px);
    font-family: $medium;
    text-align: center;
    font-weight: 500;
    text-transform: uppercase;
    color: $orange;
    font-size: rem(24px);
    line-height: rem(32px);
    @media (orientation: landscape) {
      width: rem(380px);
      margin-bottom: rem(5px);
      font-size: rem(32px);
      line-height: rem(40px);
      ._small & {
        margin-bottom: rem(32px);
        font-size: rem(23px);
        line-height: rem(32px);
      }
    }
  }
  &__text {
    margin-bottom: rem(32px);
    text-transform: uppercase;
    text-align: center;
    font-size: rem(16px);
    @media (orientation: landscape) {
      width: rem(380px);
      margin-bottom: rem(5px);
    }
  }
  &__btn {
    width: 100%;
    flex-shrink: 0;
    margin-bottom: rem(14px);
    @media (orientation: landscape) {
      width: rem(244px);
      margin-bottom: 0;
    }
  }
  &__rating {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (orientation: landscape) {
      justify-content: space-between;
      flex-direction: row;
    }
    &-img {
      display: block;
      max-width: none;
      width: calc(100% + rem(44px));
      height: rem(132px);
      margin: rem(-33px) 0 rem(-25px);
      flex-shrink: 0;
      @media (orientation: landscape) {
        width: auto;
        height: rem(88px);
        margin: 0 rem(20px);
      }
    }
    &-counter {
      position: relative;
      z-index: 1;
      small {
        display: block;
        font-size: rem(16px);
        text-transform: uppercase;
        opacity: 0.5;
        @media (orientation: landscape) {
          font-size: rem(14px);
        }
      }
      span {
        font-family: $medium;
        font-size: rem(32px);
        line-height: 1;
        white-space: nowrap;
        @media (orientation: landscape) {
          font-size: rem(36px);
        }
      }
    }
  }
  &__arrows {
    opacity: 0;
    transition: 0.4s;
    ._in-profile & {
      display: none;
    }
    path {
      animation: fade 0.5s ease-in-out infinite alternate;
      &:nth-child(1) {
        animation-delay: 0.15s;
      }
      &:nth-child(2) {
        animation-delay: 0.3s;
      }
      &:nth-child(3) {
        animation-delay: 0.45s;
      }
    }
  }
}
@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
