<template>
  <section class="rating section">
    <div class="container _small-width">
      <!-- <div class="rating__row">
        <img
          src="../assets/images/rating/img_1.png"
          alt=""
          class="rating__img"
        />
        <div class="rating__text">
          Голосуй <br />за любимого героя в&nbsp;личном кабинете
        </div>
      </div> -->
      <AppVoting :noBtns="true" :noArrows="true" title="Голосуй за&nbsp;любимого героя в&nbsp;личном кабинете" text="именно твой голос может быть решающим при выборе машины для розыгрыша"/>
      <div class="rating__row">
        <div class="rating__col">
          <div class="rating__title">ИГРА</div>
          <div class="rating__text-small">Регистрируй чек и&nbsp;выиграй деньги на&nbsp;телефон.&nbsp;20&nbsp;лучших игроков по&nbsp;итогам дня получат 1000&nbsp;руб.</div>
          <button class="btn btn--yellow" @click="toGames()">играть</button>
        </div>
        <div class="rating__col">
          <img src="../assets/images/rating/game.png" alt="" class="rating__game">
        </div>
        <div class="rating__col">
          <div class="rating__title">Рейтинг игроков</div>
          <table v-if="list?.length" class="rating__table">
            <thead>
              <tr>
                <th>Email</th>
                <th>кол-во Баллов</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="i in list" :key="i.id">
                <td>{{ i.email }}</td>
                <td>{{ i.score }}</td>
              </tr>
            </tbody>
          </table>
          <div v-else class="rating__empty">
            <span>Скоро тут появятся лучшие игроки</span>
          </div>
        </div>
      </div>
      

    </div>
  </section>
</template>

<script>
import AppVoting from './AppVoting.vue';
export default {
  components: { AppVoting },
  data: () => ({
    list: null,
    perPage: 10,
  }),
  methods: {
    toGames() {
      if (this.$store.getters.user) {
        if (this.$store.getters.user?.free_game > 0) {
          this.$modal.show("games_popup");
        } else {
          this.$modal.show("common_error", {
            title: "Недостаточно попыток",
            text: "Для того чтобы сыграть, загрузите чек с&nbsp;продукцией, участвующей в&nbsp;акции или дождитесь проверки загруженного чека.",
          });
        }
      } else {
        this.$modal.show("signup");
      }
    },
  },
  mounted() {
    this.$store
      .dispatch("GamesList", {
        page: 1,
        "per-page": this.perPage,
      })
      .then((r) => {
        console.log(r);
        this.list = r.items;
      });
  },
};
</script>

<style lang="scss" scoped>
.rating {
  &__row {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: rem(55px);
    margin-top: rem(50px);
    @media (orientation: landscape) {
      margin-top: rem(83px);
      flex-direction: row;
    }
  }
  &__img {
    height: rem(354px);
    object-fit: contain;
    @media (orientation: landscape) {
      height: rem(444px);
    }
  }
  &__text {
    text-transform: uppercase;
    color: $orange;
    font-family: $bold;
    font-size: rem(32px);
    line-height: rem(40px);
    text-align: center;
    @media (orientation: landscape) {
      margin-left: rem(47px);
      font-size: rem(40px);
      line-height: rem(48px);
      text-align: left;
    }
    &-small {
      text-transform: uppercase;
    }
  }
  &__title {
    margin-bottom: rem(15px);
    text-transform: uppercase;
    color: $orange;
    font-family: $bold;
    font-size: rem(32px);
    line-height: rem(40px);
    @media (orientation: landscape) {
      font-size: rem(40px);
      line-height: rem(48px);
    }
  }
  &__game {
    width: 100%;
    margin: rem(32px) 0;
    @media (orientation: landscape) {
      width: rem(224px);
      margin: 0;
    }
  }
  &__table {
    max-width: 100%;
    width: rem(840px);
    margin: 0 auto rem(30px);
    text-align: left;
    font-size: rem(14px);
    text-transform: uppercase;
    th {
      padding: rem(16px) 0;
      font-family: $bold;
      border-bottom: rem(2px) solid #fff;
      &:last-child {
        text-align: right;
        @media (orientation: landscape) {
          text-align: left;
        }
      }
    }
    td {
      padding: rem(8px) 0;
      border-bottom: 1px solid rgba(255, 255, 255, 0.15);
      &:last-child {
        text-align: right;
        @media (orientation: landscape) {
          text-align: left;
        }
      }
    }
  }
  .btn {
    width: 100%;
    margin-top: rem(20px);
    @media (orientation: landscape) {
      width: auto;
    }
  }
  &__col {
    width: 100%;
    text-align: center;
    @media (orientation: landscape) {
      width: auto;
      text-align: left;
      &:first-child {
        width: rem(316px);
        flex-shrink: 0;
        margin-right: rem(40px);
      }
      &:last-child {
        width: 50%;
        margin-left: rem(40px);
      }
    }
  }
  &__empty {
    margin: rem(50px) auto;
    font-size: rem(20px);
    text-transform: uppercase;
    font-family: $bold;
  }
}
</style>
