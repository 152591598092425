<template>
  <header class="header" :class="{ _modified: headerModified }">
    <div class="container">
      <div class="header__row">
        <div class="header__inner">
          <router-link
            :to="{ path: `/${locationSearch}#home` }"
            class="header__logo visible-xs"
            @click.native="showMenu = false"
          >
            <img
              src="../assets/images/logo.svg"
              class="header__logo-img"
              alt=""
            />
          </router-link>
          <!-- <a href="/" class="header__5ka visible-xs">
            <img
              src="../assets/images/5ka.svg"
              alt=""
              class="header__5ka-logo"
            />
          </a> -->
          <div v-if="user" class="visible-xs">
            <router-link
              tag="button"
              :to="{ name: 'Profile' }"
              @click.native="showMenu = false"
              class="header__login"
            >
              <span>{{ user.first_name || "Войти" }} </span>
              <div class="header__login-icon-wrapper">
                <img
                  class="header__login-icon"
                  src="../assets/images/icons/user.svg"
                  alt=""
                />
              </div>
            </router-link>
          </div>
          <div
            v-else
            class="header__login visible-xs"
            @click.prevent="
              showModal('authorization'),
                (showMenu = false),
                setWeborama(1),
                setMetrika('entrance')
            "
          >
            <span>Войти</span>
            <div class="header__login-icon-wrapper">
              <img
                class="header__login-icon"
                src="../assets/images/icons/user.svg"
                alt=""
              />
            </div>
          </div>
          <div
            @click="openInNewTab(), setMetrika('main_check_bot')"
            class="header__tg visible-xs"
          >
            <img src="../assets/images/icons/tg.svg" alt="" />
          </div>
          <div
            v-if="!showMenu"
            class="header__hamburger"
            @click="showBurger(), gtmEvent('menu_head', 'do', 'internal')"
          >
            <img
              src="../assets/images/icons/hamburger.svg"
              alt=""
              class="header__hamburger-pic"
            />
          </div>
          <button
            v-else
            class="header__menu-close visible-xs"
            @click="showBurger()"
          ></button>
          <div class="header__nav">
            <router-link
              :to="{ path: `/${locationSearch}#home` }"
              class="header__logo"
            >
              <img
                src="../assets/images/logo.svg"
                class="header__logo-img"
                alt=""
              />
            </router-link>

            <ul class="header__nav-list">
              <li
                v-for="nav in navigation"
                :key="nav.name"
                class="header__nav-link"
                :data-menuanchor="nav.anchor"
                :class="[
                  { active: $route.hash === `#${nav.anchor}` || nav.active },
                ]"
              >
                <router-link
                  v-if="nav.anchor"
                  :to="{ path: `/${locationSearch}`, hash: `#${nav.anchor}` }"
                >
                  {{ nav.name }}
                </router-link>
                <router-link
                  v-else-if="nav.page"
                  :to="{ path: `/${nav.page}` }"
                >
                  {{ nav.name }}
                </router-link>
                <span v-else-if="nav.game" @click="toGames()">
                  {{ nav.name }}
                </span>
                <a
                  v-else-if="nav.path"
                  :href="publicPath + 'docs/' + nav.path"
                  class="d-flex aic"
                  target="_blank"
                >
                  {{ nav.name }}
                </a>
                <router-link v-else :to="{ path: `/${locationSearch}` }">
                  {{ nav.name }}
                </router-link>
              </li>
            </ul>
            <div
              @click="openInNewTab(), setMetrika('main_check_bot')"
              class="header__tg"
            >
              <img src="../assets/images/icons/tg.svg" alt="" />
            </div>
            <router-link
              v-if="user"
              tag="button"
              :to="{ name: 'Profile' }"
              class="header__login"
            >
              <span>{{ user.first_name || "Войти" }} </span>
              <div class="header__login-icon-wrapper">
                <img
                  class="header__login-icon"
                  src="../assets/images/icons/user.svg"
                  alt=""
                />
              </div>
            </router-link>
            <div
              v-else
              class="header__login"
              @click.prevent="
                showModal('authorization'),
                  setWeborama(1),
                  setMetrika('entrance')
              "
            >
              <span>Войти</span>
              <div class="header__login-icon-wrapper">
                <img
                  class="header__login-icon"
                  src="../assets/images/icons/user.svg"
                  alt=""
                />
              </div>
            </div>
            <a href="/" class="header__5ka">
              <img
                src="../assets/images/5ka.svg"
                alt=""
                class="header__5ka-logo"
              />
            </a>
          </div>
        </div>

        <div class="header__menu" :class="{ _active: showMenu }">
          <div class="container">
            <div class="header__menu-wrapper">
              <div
                v-for="nav in navigation"
                :key="nav.name"
                class="header__menu-link"
                :data-menuanchor="nav.anchor"
                @click="showBurger()"
                :class="[
                  { active: $route.hash === `#${nav.anchor}` || nav.active },
                ]"
              >
                <router-link
                  v-if="nav.anchor"
                  :to="{ path: `/${locationSearch}`, hash: `#${nav.anchor}` }"
                >
                  {{ nav.name }}
                </router-link>
                <router-link
                  v-else-if="nav.page"
                  :to="{ path: `/${nav.page}` }"
                >
                  {{ nav.name }}
                </router-link>
                <span v-else-if="nav.game" @click="toGames()">
                  {{ nav.name }}
                </span>
                <a
                  v-else-if="nav.path"
                  :href="publicPath + 'docs/' + nav.path"
                  class="d-flex aic"
                  target="_blank"
                >
                  {{ nav.name }}
                </a>
                <router-link v-else :to="{ path: `/${locationSearch}` }">
                  {{ nav.name }}
                </router-link>
              </div>
            </div>
            <div class="header__menu-bottom">
              <div class="header__user-nav" v-if="user">
                <button
                  class="btn btn--bordered btn--light"
                  @click="toCheckUpload(), showBurger()"
                >
                  <span>Регистрируй чек</span>
                </button>
                <router-link
                  tag="button"
                  :to="{ name: 'Profile' }"
                  class="btn btn--yellow"
                  @click.native="showBurger()"
                >
                  <span>Войти</span>
                </router-link>
                <div
                  class="header__logout btn btn--bordered _red"
                  @click="logout(), showBurger()"
                >
                  <svg
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21.269 11.9997L8.49121 11.9997"
                      stroke="#EF2D30"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M18.7285 8.7251L22.0181 12.0001L18.7285 15.2761"
                      stroke="#EF2D30"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M13.5059 16.625L13.5059 18.25C13.5059 19.3546 12.6104 20.25 11.5059 20.25L4.98193 20.25C3.87736 20.25 2.98192 19.3546 2.98192 18.25L2.98192 5.75C2.98192 4.64543 3.87735 3.75 4.98192 3.75L11.5059 3.75C12.6104 3.75 13.5059 4.64543 13.5059 5.75L13.5059 7.375"
                      stroke="#EF2D30"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>

                  <span>Выйти</span>
                </div>
              </div>
              <div
                v-else
                class="btn btn--yellow"
                @click.prevent="
                  showModal('authorization'),
                    showBurger(),
                    setWeborama(1),
                    setMetrika('entrance')
                "
              >
                <span>Войти</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="header__5ka-wrapper visible-xs">
      <div class="container">
        <hr />
        <a href="/" class="header__5ka">
          <img src="../assets/images/5ka.svg" alt="" class="header__5ka-logo" />
        </a>
        <hr />
      </div>
    </div>
  </header>
</template>

<script>
export default {
  props: ["headerModified"],
  data() {
    return {
      publicPath: process.env.BASE_URL,
      phone: "7 951 376-98-787",
      email: "Ivanov_ivan@mail.ru",
      showMenu: false,
      locationSearch: "",
      navigation: [
        {
          name: "Как выиграть?",
          anchor: "steps",
          gtm: true,
          gtmText: "part",
        },
        {
          name: "Призы",
          anchor: "prizes",
          gtm: true,
          gtmText: "prizes",
        },
        {
          name: "Играть",
          game: true,
        },
        {
          name: "Победители",
          anchor: "winners",
          gtm: true,
          gtmText: "winners",
        },
        {
          name: "Трансляция",
          anchor: "streams",
          gtm: true,
          gtmText: "streams",
        },
        {
          name: "Продукты",
          page: "new",
        },
        {
          name: "Правила",
          path: "rules.pdf",
        },
        {
          name: "Вопросы и ответы",
          anchor: "faq",
          gtm: true,
          gtmText: "faq",
        },
      ],
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    homePage() {
      if (this.$route.path === "/") {
        return true;
      } else {
        return false;
      }
    },
  },

  methods: {
    showModal(name) {
      this.$modal.show(name);
    },
    logout() {
      this.$store.dispatch("LogOut");
      document.location.reload();
    },
    showBurger() {
      this.showMenu = !this.showMenu;
    },
    toFeedback() {
      this.$modal.show("feedback");
    },
    wordVariant(items) {
      return items === 1 ? "сила" : items > 1 && 4 > items ? "силы" : "сил";
    },
    toGames() {
      if (this.$store.getters.user) {
        if (this.$store.getters.user?.free_game > 0) {
          this.$modal.show("games_popup");
        } else {
          this.$modal.show("common_error", {
            title: "Недостаточно попыток",
            text: "Для того чтобы сыграть, загрузите чек с&nbsp;продукцией, участвующей в&nbsp;акции или дождитесь проверки загруженного чека.",
          });
        }
      } else {
        this.$modal.show("signup");
      }
    },
    openInNewTab() {
      const promocode = this.$store.getters.user?.promocode || null;
      let click_id = localStorage.getItem("click_id") || null;
      window
        .open(
          `https://t.me/jaguar_energy_promo_bot${
            promocode ? `?start=${promocode}` : ""
          }${click_id ? `?start=separator${click_id}` : ""}`,
          "_blank"
        )
        .focus();
    },
  },
  mounted() {
    if (window.location.search.indexOf("utm_source") > -1) {
      this.locationSearch = window.location.search;
    }
  },
};
</script>

<style scoped lang="scss">
.header {
  position: fixed;
  top: 0px;
  left: 0;
  width: 100%;
  background: $black;
  z-index: 1000;
  transition: 0.3s;
  &._modified {
  }
  .container {
    position: relative;
    padding-top: 0;
    padding-bottom: rem(0px);
    .header--mob_active & {
      box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.25);
    }
  }
  &__row {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
  }
  &.header--mob_active {
    .header__hamburger {
      opacity: 0;
      visibility: hidden;
    }

    .header__menu_mob {
      display: flex;
    }
    .header__menu-close {
      opacity: 1;
    }
  }

  &__inner {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    padding: rem(16px) 0;
    background: $black;
    z-index: 10;
  }
  &__nav {
    position: relative;
    display: none;
    width: 100%;
    align-items: center;
    flex-direction: column;
    &-info {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: rem(24px);
      &-item {
        display: flex;
        align-items: center;
        font-size: rem(14px);
        font-family: $bold;
        text-transform: uppercase;
        &:not(:last-of-type) {
          margin-bottom: rem(13px);
        }
        svg,
        img {
          width: rem(16px);
          margin-right: rem(8px);
        }
      }
    }
  }

  &__nav-link {
    margin-bottom: 0;
    font-size: rem(20px);
    line-height: normal;
    text-align: center;
    font-weight: 450;
    white-space: nowrap;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
  }

  &__user-nav {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    gap: rem(16px);
  }
  &__logout {
    display: flex;
    align-items: center;
    font-family: $medium;
    cursor: pointer;
    svg {
      display: block;
      width: rem(24px);
      margin-right: rem(8px);
    }
  }
  &__lk {
    height: rem(30px);
    margin-left: auto;
    & + .header__hamburger {
      margin: 0 0 0 rem(10px);
    }
    & + .header__menu-close {
      margin: 0 0 0 rem(10px);
    }
  }
  &__hamburger {
    display: block;
    flex-shrink: 0;
    width: rem(24px);
    margin: 0 0 0 rem(14px);
    img {
      width: rem(24px);
      margin-left: auto;
    }
  }
  &__tg {
    width: rem(32px);
    margin-left: rem(14px);
    cursor: pointer;
  }
  &__menu {
    position: fixed;
    width: 100vw;
    top: 0px;
    right: 0;
    height: calc(var(--vhH, 1vh) * 100);
    padding-top: calc(var(--vhH, 1vh) * 15);
    padding-bottom: rem(24px);
    transform: translateY(-130%);
    transition: 0.5s;
    background: $black;
    opacity: 0.5;
    z-index: 1;
    &._active {
      transform: translateY(0%);
      opacity: 1;
    }
    .container {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }
    &-wrapper {
      display: flex;
      flex-direction: column;
      margin-bottom: auto;
    }
    &-link {
      font-family: $medium;
      font-size: rem(20px);
      margin-bottom: calc(var(--vhH, 1vh) * 3);
    }
    &-bottom {
      display: flex;
      width: 100%;
    }
    .btn {
      min-width: auto;
      width: 100%;
    }
    &-img {
      position: absolute;
      object-fit: contain;
    }
  }
  &__logo {
    flex-grow: 0;
    flex-shrink: 0;
    align-self: flex-start;
    margin-right: auto;
    &-img {
      height: rem(32px);
    }
  }

  &__5ka {
    display: flex;
    align-items: center;
    font-size: rem(14px);
    line-height: rem(16px);
    font-family: $medium;
    // cursor: pointer;
    &-logo {
      height: rem(32px);
    }
    span {
      flex: 0;
    }
    &-wrapper {
      position: absolute;
      top: 100%;
      width: 100%;
      background: $black;
      transition: 0.4s;
      z-index: -1;
      .container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .header__5ka {
          padding: rem(8px) 0;
        }
      }
      hr {
        display: block;
        width: 100%;
        border-top: 1px solid #262626;
      }
      ._modified & {
        transform: translateY(-100%);
      }
    }
  }
  &__login {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    text-transform: uppercase;
    color: $orange;
    font-family: $medium;
    font-size: rem(13px);
    transition: 0.4s;
    z-index: 2;
    cursor: pointer;
    &-icon {
      @include size(45%);
      object-fit: contain;
      transition: 0.4s;
      &-wrapper {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        @include size(rem(32px));
        border-radius: 50%;
        background: $orange;
        margin-left: rem(8px);
        transition: 0.4s;
      }
    }
  }
  &__menu-close {
    cursor: pointer;
    width: rem(24px);
    height: rem(24px);
    flex-shrink: 0;
    margin: 0 0 0 rem(14px);
    background: url("../assets/images/icons/close-white.svg") no-repeat center;
    background-size: 100%;
    z-index: 10;
  }
  @media (orientation: landscape) {
    &__logo {
      margin-bottom: rem(-15px);
      margin-right: rem(50px);
      &-img {
        height: rem(155px);
      }
    }
    &__red {
      margin-right: rem(50px);
      // cursor: pointer;
      &-logo {
        height: rem(60px);
      }
    }

    &__hamburger {
      top: rem(53px);
      width: rem(59px);
    }
    &__menu {
      &-link {
        font-size: rem(50px);
      }
    }
    &__login {
      &-icon {
        height: rem(70px);
      }
    }
    &__menu-close {
      top: rem(38px);
      right: rem(0px);
      width: rem(64px);
      height: rem(64px);
    }
  }
  @media (orientation: landscape) {
    .container {
      padding-top: 0;
      padding-bottom: 0;
      display: flex;
    }
    &__login {
      display: flex;
      align-items: center;
      padding: rem(2px) rem(2px) rem(2px) rem(18px);
      margin: auto 0;
      font-size: rem(14px);
      border: 2px solid $orange;
      border-radius: rem(30px);
      line-height: 1;
      &-icon {
        &-wrapper {
          @include size(rem(40px));
          margin-left: rem(10px);
        }
      }
      &:hover {
        color: $black;
        background: $orange;
        .header__login-icon-wrapper {
          background: $white;
        }
      }
      span {
        position: relative;
        display: block;
        // max-width: rem(50px);
        // padding-right: rem(20px);
        // overflow: hidden;
        // &::before {
        //   content: '';
        //   position: absolute;
        //   right: 0;
        //   top: 0;
        //   width: rem(20px);
        //   b
        // }
      }
    }
    &__row {
      align-items: flex-start;
    }
    &__menu {
      display: none;
    }
    &__menu-logos {
      display: none;
    }
    &__tg {
      width: rem(48px);
      margin-right: rem(16px);
      margin-left: 0;
    }
    &__logo {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-grow: 0;
      flex-shrink: 0;
      height: rem(73px);
      margin: 0;
      transition: 0.4s;

      &-img {
        height: 100%;
      }
    }
    &__5ka {
      margin-left: rem(20px);
      &-logo {
        height: rem(48px);
      }
    }
    &__inner {
      padding: rem(10px) 0;
    }
    &__user-nav {
      display: flex;
      align-items: center;
      flex-direction: row;
    }
    &__logout {
      margin-top: 0;
      margin-left: rem(46px);
      svg {
        width: rem(31px);
        margin-left: 0;
      }
    }
    &__nav {
      display: flex;
      align-items: center;
      flex-direction: row;
      &_mob {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      &-list {
        display: flex;
        justify-content: center;
        flex-direction: row;
        align-items: center;
        margin: auto;
        transition: 0.4s;
      }
      &-link {
        position: relative;
        font-size: rem(14px);
        margin: 0;
        line-height: 1;
        font-family: $medium;
        text-align: center;
        border-radius: rem(8px);
        transition: all 0.3s ease-in-out;
        border-bottom: 1px solid transparent;
        cursor: pointer;
        &.active,
        &.router-link-exact-active,
        &:hover {
          color: $orange;
          cursor: default;
          path {
            fill: $green_dark;
          }
        }
        span {
          cursor: pointer;
        }
        svg {
          margin-left: rem(5px);
        }
        &:not(:last-of-type) {
          margin-right: rem(10px);
        }
      }
    }
    &__hamburger,
    &__menu_mob {
      display: none;
    }
  }
}

.header__menu_mob {
  display: none;
  input {
    background: none;
    border: none;
    outline: none;
    color: #fff;
    margin-right: rem(-30px);
    font-weight: 700;
    font-size: rem(14px);
    opacity: 1;
    &::placeholder {
      opacity: 1;
      color: #fff;
    }
  }
}

.header__menu_mob.active {
  display: flex !important;
}
</style>
