<template>
  <section class="streams section" id="streams">
    <VueSlickCarousel
      ref="slider"
      v-bind="settings"
      @beforeChange="beforeChange"
      class="streams-slider"
      @afterChange="gtmEvent('click', 'stream_play')"
    >
      <div
        v-for="(item, i) in streams"
        :key="i"
        class="streams-slider__slide"
        @click="gtmEvent('click', 'stream_play')"
      >
        <div class="container _small-width">
          <video
            v-if="item.link && !item.disabled"
            :src="item.link"
            class="iframe"
            width="853"
            height="480"
            controls
          ></video>
          <img
            v-else
            src="../assets/images/stream-bg.jpg"
            class="streams-slider__slide-img"
          />
          <!-- <div class="streams-slider__slide-overlay">
            <img src="../assets/images/icons/play.png" alt="" />
            <span> Смотри видео<br />команд</span>
          </div> -->
          <div class="streams-slider__slide-text">{{ item.date }}</div>
        </div>
      </div>
      <template #prevArrow="row">
        <div class="custom-arrow">
          {{ row.currentSlide > 0 ? streams[row.currentSlide - 1].date : null }}
        </div>
      </template>
      <template #nextArrow="row">
        <div class="custom-arrow">
          {{
            streams.length !== row.currentSlide + 1
              ? streams[row.currentSlide + 1].date
              : null
          }}
        </div>
      </template>
    </VueSlickCarousel>
  </section>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";

export default {
  components: {
    VueSlickCarousel,
  },
  data: () => ({
    settings: {
      arrows: true,
      dots: false,
      slidesToShow: 1,
      initialSlide: 0,
      centerMode: true,
      centerPadding: "13%",
      infinite: true,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1023,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            centerMode: false,
            fade: true,
            arrows: true,
            dots: false,
          },
        },
      ],
    },
    streams: [
      {
        link: "https://storage.yandexcloud.net/jaguar-energy/jaguar_video_1.mp4",
        disabled: false,
      },
      {
        link: "https://storage.yandexcloud.net/jaguar-energy/jaguar_video_2.mp4",
        disabled: false,
      },
      {
        link: "https://storage.yandexcloud.net/jaguar-energy/jaguar_video_3.mp4",
        disabled: false,
      },
      {
        link: "https://storage.yandexcloud.net/jaguar-energy/jaguar_video_4.mp4",
        disabled: false,
      },
    ],
  }),
  methods: {
    /* eslint-enable */
    stopAllYouTubeVideos() {
      var iframes = document.querySelectorAll(".iframe");
      Array.prototype.forEach.call(iframes, (iframe) => {
        let src = iframe.src;
        iframe.src = "";
        iframe.src = src;
      });
    },
    beforeChange(oldSlideIndex, newSlideIndex) {
      if (oldSlideIndex !== newSlideIndex) {
        document
          .querySelectorAll(".slick-center + .slick-cloned")
          .forEach((next) => {
            // timeout required or Slick will overwrite the classes
            setTimeout(() =>
              next.classList.add("slick-current", "slick-center")
            );
          });
        this.stopAllYouTubeVideos();
      }
    },
  },
  mounted() {
    // this.stopAllYouTubeVideos();
  },
};
</script>

<style lang="scss">
.streams {
  position: relative;
  padding-top: rem(110px);
  padding-bottom: rem(50px);
  &__text {
    max-width: 100%;
    margin: rem(25px) 0 rem(30px);
    font-size: rem(20px);
    letter-spacing: 0.08em;
    text-align: center;
    a {
      border-bottom: 1px solid currentColor;
      transition: 0.4s;
      &:hover {
        border-color: transparent;
      }
    }
  }
  &-slider {
    width: 100%;
    max-width: 100%;
    margin: auto;
    .slick-next,
    .slick-prev {
      top: 50% !important;
    }
    &__slide {
      position: relative;
      text-align: center;
      font-size: rem(20px);
      .slick-current & {
        &:hover {
          .streams-slider__slide-overlay {
            opacity: 1;
          }
        }
      }
      &-overlay {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        @include size(100%);
        background: rgba(0, 0, 0, 0.4);
        opacity: 1;
        transition: 0.4s;
        cursor: pointer;
        img {
          @include size(rem(115px));
        }
        span {
          display: block;
          margin-top: rem(-15px);
          margin-bottom: rem(20px);
          font-size: rem(12px);
          line-height: rem(14px);
          text-transform: uppercase;
          font-family: $medium;
        }
      }
      &-img,
      iframe,
      video {
        width: 100%;
        border-radius: rem(24px);
        border: rem(2px) solid $orange;
      }
      iframe,
      video {
        height: rem(250px);
      }
      &-text {
        position: absolute;
        bottom: rem(10px);
        left: 50%;
        transform: translateX(-50%);
        font-size: rem(14px);
        font-family: $reg;
        text-transform: uppercase;
      }
    }
    .slick-dots {
      top: calc(100% + #{rem(35px)});
    }
    .slick-current {
      z-index: 100000;
    }
  }
  &__btn {
    margin: 0 auto rem(56px);
  }
  @media (min-width: $md) and (orientation: landscape) {
    padding-top: rem(150px);
    padding-bottom: 0;
    &__text {
      margin: rem(-25px) auto rem(30px);
      font-size: rem(24px);
      letter-spacing: 0.08em;
      color: $yellow;
    }
    &-slider {
      position: relative;
      width: 100%;
      height: rem(582px);
      .slick-track {
        display: flex !important;
        align-items: center;
      }
      .slick-next,
      .slick-prev {
        top: 50%;
      }
      &__slide {
        display: flex !important;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: rem(40px);
        height: rem(582px);
        &-overlay {
          opacity: 0;
          img {
            @include size(rem(235px));
          }
          span {
            display: block;
            margin-top: rem(-45px);
            margin-bottom: 0;
            font-size: rem(14px);
            line-height: rem(18px);
          }
        }
        &-img,
        iframe,
        video {
          border-radius: rem(70px);
          height: rem(348px);
          opacity: 0.4;
          transition: 0.4s ease-in-out;
          .slick-current & {
            height: rem(582px);
            opacity: 1;
          }
        }
        &-text {
          position: absolute;
          bottom: rem(30px);
          left: 50%;
          transform: translateX(-50%);
          font-size: rem(18px);
          line-height: 1;
          z-index: 10;
        }
      }
      .slick-dots {
        top: calc(100% + #{rem(25px)}) !important;
      }
    }
    &__btn {
      display: none;
    }
  }
}
</style>
