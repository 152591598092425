<template>
  <section class="prizes section" id="prizes">
    <div class="prizes__content">
      <div
        class="section-title _yellow"
        v-html="title ? title : 'ПРИЗЫ'"
        :class="{ _small: title }"
      ></div>
      <div class="prizes__text">
        Призы ВЫДАЮТСЯ в&nbsp;виде сертификатов с&nbsp;номиналом приза
      </div>
      <VueSlickCarousel
        ref="slider"
        v-bind="settings"
        @beforeChange="beforeChange"
        class="prizes-list"
      >
        <div v-for="(item, i) in prizes" :key="i" class="prizes-list__item">
          <div class="prizes-list__item-img-wrapper">
            <img
              :src="require(`../assets/images/prizes/${item.img}`)"
              alt=""
              class="prizes-list__item-img"
            />
          </div>
          <div class="prizes-list__item-title" v-html="item.text"></div>
        </div>
      </VueSlickCarousel>
    </div>
  </section>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";

export default {
  components: { VueSlickCarousel },
  props: ["title", "mainPrizeSubtitle"],
  data: function () {
    return {
      settings: {
        arrows: true,
        dots: false,
        slidesToShow: 3,
        centerMode: true,
        centerPadding: "20px",
        infinite: true,
        responsive: [
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              fade: false,
              arrows: true,
            },
          },
        ],
      },
      prizes: [
        {
          img: "prize-1.png",
          text: "iphone 15 PRO",
        },
        {
          img: "prize-2.png",
          text: "airpods PRo",
        },
        {
          img: "prize-3.png",
          text: "MacBook AIR 13",
        },
        {
          img: "prize-4.png",
          text: "Часы Apple Watch&nbsp;9",
        },
        {
          img: "prize-5.png",
          text: "400 баллов на&nbsp;Карту лояльности &laquo;Х5&raquo; Клуба",
        },
        {
          img: "prize-6.png",
          text: "Игровая приставка Sony PlayStation 5&nbsp;Slim 1TB",
        },
        {
          img: "prize-7.png",
          text: "Автомобиль",
        },
      ],
    };
  },
  methods: {
    beforeChange(oldSlideIndex, newSlideIndex) {
      if (oldSlideIndex !== newSlideIndex) {
        document
          .querySelectorAll(".slick-center + .slick-cloned")
          .forEach((next) => {
            // timeout required or Slick will overwrite the classes
            setTimeout(() =>
              next.classList.add("slick-current", "slick-center")
            );
          });
      }
    },
  },
};
</script>

<style lang="scss">
.prizes {
  position: relative;
  width: 100%;
  align-items: center;
  padding-top: rem(40px);
  padding-bottom: rem(0px);
  flex-direction: column;
  z-index: 2;
  &__content {
    width: 100%;
    background: url("../assets/images/prizes/bg.png") no-repeat center top
      rem(10px);
    background-size: auto 86%;
    ._no-bg & {
      background: none;
    }
  }
  &._profile {
    padding-bottom: 0;
  }
  &-list {
    display: flex;
    flex-direction: column;
    margin-bottom: rem(50px);
    &__item {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      &-img {
        max-height: 100%;
        &-wrapper {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: rem(305px);
        }
      }
      &-title {
        margin-top: rem(25px);
        font-family: $medium;
        font-weight: 500;
        font-size: rem(20px);
        text-transform: uppercase;
      }
    }
  }
  &__text {
    max-width: 90%;
    margin: auto;
    text-transform: uppercase;
    text-align: center;
    font-size: rem(14px);
    line-height: 1;
  }
  .section-title {
    margin-bottom: rem(10px);
    &._small {
      font-size: rem(24px);
    }
  }

  @media (orientation: landscape) {
    padding-top: 0;
    padding-bottom: 0;
    overflow: visible;
    z-index: 1;
    .container {
      display: flex;
      padding-top: rem(0px);
    }
    .section-title {
      margin-bottom: rem(10px);
      &._small {
        font-size: rem(48px);
      }
    }
    &__content {
      flex-direction: column;
      align-items: center;
      padding-bottom: 0;
      padding-top: 0;
      background: url("../assets/images/prizes/bg.png") no-repeat center top
      rem(10px);
      background-size: auto 100%;
    }
    &__text {
      font-size: rem(16px);
      text-align: center;
    }
    &-list {
      width: 95%;
      margin: 0 auto rem(0px);
      flex-direction: row;
      justify-content: center;
      align-items: center;
      &.slick-slider {
        .slick-track {
          display: flex;
          align-items: center;
        }
        .slick-prev,
        .slick-next {
          top: 43%;
        }
        .slick-prev {
          left: 29%;
        }
        .slick-next {
          right: 29%;
          left: auto;
        }
      }
      &__item {
        max-height: 100%;
        transform: scale(0.6);
        transition: 0.4s;
        .slick-current & {
          transform: scale(1);
        }
        &__content {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
        }
        &-img {
          width: auto;
          max-height: 100%;
          margin: auto;
          object-fit: contain;
          &-wrapper {
            height: rem(430px);
          }
        }
        &-title {
          margin-top: rem(16px);
          font-size: rem(24px);
        }
        &__text {
          max-width: 80%;
          margin: 0 auto rem(6px);
          font-size: rem(26px);
          line-height: 1;
          text-align: center;
        }
        .btn {
          margin-top: auto;
          pointer-events: none;
          cursor: default;
        }
      }
    }
  }
}
</style>
