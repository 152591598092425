var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{key:_vm.indexForm,staticClass:"form"},[_c('inputText',{class:{ error: _vm.validationStatus(_vm.$v.name) || _vm.errorMessage.name },attrs:{"label":"Имя","placeholder":"Введи имя","field":_vm.name,"error":(!_vm.$v.name.required && _vm.$v.name.$error
        ? 'Обязательное поле'
        : !_vm.$v.name.rus && _vm.$v.name.$error
        ? 'Введи текст на русском языке'
        : '') || _vm.errorMessage.name},on:{"input":function($event){_vm.name = $event}}}),_c('inputText',{class:{
      error: _vm.validationStatus(_vm.$v.email) || _vm.errorMessage.email,
    },attrs:{"label":"E-mail","placeholder":"Введи EMAIL","field":_vm.email,"error":(!_vm.$v.email.required && _vm.$v.email.$error
        ? 'Обязательное поле'
        : !_vm.$v.email.email && _vm.$v.email.$error
        ? 'Некорректный e-mail'
        : '') || _vm.errorMessage.email},on:{"input":function($event){_vm.email = $event}}}),_c('Select',{class:{
      error: _vm.validationStatus(_vm.$v.subject) || _vm.errorMessage.subject,
    },attrs:{"color":"grey","options":[
      { id: 1, label: 'Регистрация в акции' },
      { id: 2, label: 'Призовой фонд' },
      { id: 3, label: 'Вопросы по правилам' },
      { id: 4, label: 'Технические вопросы' },
      { id: 5, label: 'Другое' },
    ],"labelText":"Тема","placeholder":"выбери тему","error":(!_vm.$v.subject.required && _vm.$v.subject.$error
        ? 'Обязательное поле'
        : '') || _vm.errorMessage.subject},on:{"input":function($event){_vm.subject = $event}}}),_c('textArea',{key:_vm.indexForm2,class:{
      error: _vm.validationStatus(_vm.$v.text) || _vm.errorMessage.text,
    },attrs:{"label":"Текст обращения","placeholder":"текст обращения","field":_vm.text,"error":(!_vm.$v.text.required && _vm.$v.text.$error ? 'Обязательное поле' : '') ||
      _vm.errorMessage.text},on:{"input":function($event){_vm.text = $event}}}),_vm._v(" "),_c('File',{attrs:{"chosenFileName":_vm.chosenFileName},on:{"submitMedia":_vm.submitMedia,"deleteFile":_vm.deleteFile}}),_c('div',{staticClass:"feedback__checkbox_list"},[_c('checkBox',{class:{
        error: _vm.validationStatus(_vm.$v.checkbox) || this.errorMessage.checkbox,
      },attrs:{"label":`Я даю согласие на&nbsp;обработку <a href='${_vm.publicPath}docs/terms-of-use.pdf' target='_blank' class='_yellow _undeline-link'>персональных данных</a>`,"field":_vm.checkbox,"error":this.errorMessage.checkbox},on:{"input":function($event){_vm.checkbox = $event}}})],1),_c('div',{staticClass:"captcha",class:{
      error: _vm.validationStatus(_vm.$v.captcha) || _vm.errorMessage.captcha,
    }},[_c('vue-recaptcha',{ref:"recaptcha",attrs:{"loadRecaptchaScript":true,"sitekey":"6LcZvb0UAAAAAGWachHAtQkfEN1WtATm-nSiVDgJ"},on:{"verify":_vm.onVerify,"expired":_vm.onCaptchaExpired}}),(_vm.validationStatus(_vm.$v.captcha) || _vm.errorMessage.captcha)?_c('div',{staticClass:"error-hint"},[_vm._v(" "+_vm._s((!_vm.$v.captcha.required && _vm.$v.captcha.$error ? 'Обязательное поле' : '') || _vm.errorMessage.captcha)+" ")]):_vm._e()],1),_c('div',{staticClass:"form__footer"},[_c('button',{staticClass:"btn btn--yellow w-100",class:{ loading: _vm.submitStatus == 'PENDING' },attrs:{"type":"button"},on:{"click":function($event){_vm.submit(), _vm.gtmEvent('ask_question', 'click', 'internal')}}},[_c('span',[_vm._v("ОТправить")])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }