<template>
  <div class="prizes-main-prize" id="prizes">
    <div class="container _small-width">
      <div class="prizes-main-prize__content">
        <div class="prizes-main-prize__title section-title _white">
          главный приз
        </div>
        <div class="prizes-main-prize__subtitle _white">
          Будет определен по&nbsp;итогам голосования
        </div>
        <div class="prizes-main-prize__text" v-html="mainPrizeSubtitle"></div>
      </div>
    </div>
    <img
      src="../assets/images/prizes/bg-2.png"
      alt=""
      class="prizes-main-prize__cars"
    />
  </div>
</template>

<script>
export default {
  props: ["mainPrizeSubtitle"],
};
</script>

<style lang="scss" scoped>
.prizes {
  &-main-prize {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    &.mt-big {
      margin-top: rem(60px);
      @media (orientation: landscape) {
        margin-top: rem(60px);
      }
    }
    &._profile {
      padding-bottom: rem(0px);
    }
    &__content {

      @media (orientation: landscape) {
        margin-top: rem(30px);
      }

      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-bottom: rem(10px);
      align-items: center;
      text-align: center;
    }
    &__title.section-title {
      margin-bottom: rem(10px);
    }
    &__subtitle {
      margin-bottom: rem(10px);
      font-family: $reg;
      text-transform: uppercase;
      font-size: rem(16px);
    }
    &__text {
      margin-bottom: rem(20px);
      font-family: $light-font;
      text-transform: uppercase;
      &:empty {
        display: none;
      }
    }
    &__cars {
      max-width: none;
      width: 100%;
      ._profile & {
        width: 120%;
      }
    }
  }
  @media (orientation: landscape) {
    &-main-prize {
      position: relative;
      padding-bottom: rem(67px);
      &.mt-big {
        margin-top: rem(100px);
      }
      &__content {
        position: absolute;
        top: 0;
        left: 0;
        padding-bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      &__title.section-title {
        margin-bottom: rem(10px);
      }
      &__text {
        margin-bottom: rem(12px);
        font-size: rem(24px);
        line-height: rem(32px);
      }
      &__cars {
        width: 100%;
        ._profile & {
          width: 100%;
        }
      }
    }
  }
}
</style>
