<template>
  <div class="hero section" id="home">
    <div class="container">
      <div class="hero__date">
        <img src="../assets/images/icons/calendar.svg" alt="" />
        <span>c 15 августа по 31 октября</span>
      </div>
      <div class="hero__suptitle">Участвуй в гонке за&nbsp;призами</div>
      <div class="hero__title">
        <img src="../assets/images/hero/title.png" alt="" />
      </div>

      <img src="../assets/images/products.png" alt="" class="hero__products hidden-xs" />
      <div class="hero-block">
        <button
          class="btn btn--bordered btn--light btn--lg"
          @click="
            $modal.show('uploading_options'),
              setWeborama(10),
              setMetrika('main_check')
          "
        >
          <span>Регистрируй чек</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    // Feedback,
  },
  data: () => ({
    publicPath: process.env.BASE_URL,
  }),
  computed: {
    user() {
      return this.$store.getters.user;
    },
  },
  methods: {
    showModal(name) {
      this.$modal.show(name);
    },
  },
  mounted() {
    // this.toCheckUpload()
    document.querySelector(".hero").addEventListener("mousemove", parallax);
    function parallax(event) {
      this.querySelectorAll(".js-parallax-wrap > *").forEach((shift) => {
        const position = shift.getAttribute("value");
        const x = (window.innerWidth - event.pageX * position) / 200;

        shift.style.transform = `translateX(${x}px)`;
      });
    }
  },
};
</script>
<style lang="scss">
.hero {
  position: relative;
  display: flex;
  height: calc(var(--vhH, 1vh) * 95);
  align-items: center;
  padding-top: calc(var(--vhH, 1vh) * 20);
  padding-bottom: 0;
  flex-direction: column;
  background: url("../assets/images/hero/hero-m.jpg") no-repeat center bottom;
  background-size: 100%;
  z-index: 30 !important;

  .container {
    position: relative;
    height: 100%;
    color: #fff;
    z-index: 30;
  }

  &__title {
    display: flex;
    justify-content: center;
    margin-bottom: rem(38px);
    img {
      height: rem(32px);
      object-fit: contain;
    }
  }
  &__suptitle {
    font-size: rem(20px);
    line-height: 1;
    text-align: center;
    text-transform: uppercase;
    font-family: $bold;
  }
  &__products {
    height: rem(220px);
    margin-left: rem(-15px);
    margin-bottom: rem(14px);
  }
  &-block {
    position: fixed;
    margin: auto;
    display: flex;
    width: calc(100% - #{rem(48px)});
    left: 50%;
    bottom: calc(var(--vhH, 1vh) * 10);
    transform: translateX(-50%);
    flex-direction: column;
    align-items: center;
    z-index: 1000;
    .btn {
      width: 100%;
    }
  }
  &__date {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: rem(16px);
    font-size: rem(16px);
    font-family: $medium;
    img {
      width: rem(24px);
      display: block;
      margin-right: rem(8px);
    }
  }
  &__rules {
    position: relative;
    margin-top: rem(10px);
    font-size: rem(20px);
    color: $brown;
    line-height: 1;
    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      @include size(100%, 250%);
      display: block;
      transform: translate(-50%, -50%);
    }
  }

  @media (orientation: landscape) {
    position: relative;
    flex-direction: column;
    height: 100vh;
    padding-top: 0;
    padding-bottom: 0;
    background: url("../assets/images/hero/hero.png") no-repeat center;
    background-size: auto 100%;
    .container {
      position: relative;
      padding-top: vh(115px);
      height: 100%;
    }
    &__subtitle {
      margin-bottom: vh(12px);
      font-size: vh(35px);
      letter-spacing: vh(1px);
      text-transform: uppercase;
    }
    &__title {
      img {
        height: vh(47px);
      }
    }
    &__suptitle {
      font-size: vh(35px);
      text-align: center;
    }
    &__products {
      position: absolute;
      bottom: vh(56px);
      left: rem(80px);
      height: vh(500px);
      margin-left: 0;
      margin-bottom: 0;
    }
    &-block {
      bottom: rem(48px);
      width: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      .btn {
        width: auto;
      }
    }
    &__date {
      margin-bottom: vh(14px);
      font-size: vh(20px);
    }
    &__rules {
      margin-top: rem(20px);
      font-size: rem(22px);
    }
  }
  @media (min-width: $md) and (max-height: 750px) {
  }

  @media (max-width: $xlg) {
  }
}
</style>
