<template>
  <div class="form">
    <ul class="modal-nav">
      <li class="modal-nav__item _pointer" @click="showLogInModal()">Вход</li>
      <li class="modal-nav__item active">Регистрация</li>
    </ul>
    <div class="form__fields">
      <inputText
        label="Ваше имя"
        placeholder="Введи имя"
        :field="first_name"
        :class="{
          error: validationStatus($v.first_name) || errorMessage.first_name,
        }"
        :error="
          (!$v.first_name.required && $v.first_name.$error
            ? 'Обязательное поле'
            : !$v.first_name.rus && $v.first_name.$error
            ? 'Введи текст на русском языке'
            : '') || errorMessage.first_name
        "
        @input="first_name = $event"
      />
      <inputText
        label="E-mail"
        :field="email"
        placeholder="Введи EMAIL"
        type="email"
        :class="{
          error: validationStatus($v.email) || this.errorMessage.email,
        }"
        :error="
          (!$v.email.required && $v.email.$error
            ? 'Обязательное поле'
            : !$v.email.email && $v.email.$error
            ? 'Некорректный e-mail'
            : '') || this.errorMessage.email
        "
        @input="email = $event"
      />
      <inputText
        label="Карта «Х5 Клуба» (необязательно)"
        placeholder="Номер карты"
        :field="x5id"
        maxlength="16"
        @input="x5id = $event"
        :class="{
          error: validationStatus($v.x5id) || errorMessage.x5id,
        }"
        :error="
          (!$v.x5id.maxLength && $v.x5id.$error
            ? 'Максимум 16 символов'
            : '') || errorMessage.x5id
        "
      />
    </div>
    <div class="form__note">
      *Для получения гарантированного приза необходимо заполнить номер карты
      &laquo;Х5&nbsp;Клуба&raquo;
    </div>
    <div class="form__checkbox-list">
      <checkBox
        :label="`Я ознакомился с&nbsp;<a href='${publicPath}docs/terms-of-use.pdf' class='_undeline-link _yellow' target='_blank'>положением о&nbsp;конфиденциальности</a> и&nbsp;<a href='${publicPath}docs/rules.pdf' class='_undeline-link _yellow' target='_blank'>правилами акции</a>`"
        :field="agree_rules"
        :class="{
          error: validationStatus($v.agree_rules) || errorMessage.agree_rules,
        }"
        :error="errorMessage.agree_rules"
        @input="agree_rules = $event"
      />
      <checkBox
        :label="`Я&nbsp;согласен получать информационные сообщения от&nbsp;компании ООО &laquo;X5&nbsp;Клуб&raquo; и&nbsp;Организатора акции`"
        :field="agree_pd"
        :class="{
          error: validationStatus($v.agree_pd) || errorMessage.agree_pd,
        }"
        :error="errorMessage.agree_pd"
        @input="agree_pd = $event"
      />
    </div>
    <div
      class="captcha"
      :class="{
        error: validationStatus($v.recaptcha) || errorMessage.recaptcha,
      }"
    >
    <!-- <div 
  style="height: 100px"
  id="captcha-container2"
  class="smart-captcha"
></div> -->
      <vue-recaptcha
        ref="recaptcha"
        :loadRecaptchaScript="true"
        sitekey="6LcZvb0UAAAAAGWachHAtQkfEN1WtATm-nSiVDgJ"
        @verify="onVerify"
        @expired="onCaptchaExpired"
      />
      <div class="error-hint" v-if="validationStatus($v.recaptcha) || errorMessage.recaptcha">
        {{ (!$v.recaptcha.required && $v.recaptcha.$error ? 'Обязательное поле' : '') || errorMessage.recaptcha }}
      </div>
    </div>
    <div class="form__footer">
      <button
        type="button"
        class="btn btn--yellow w-100"
        @click="submit()"
        :class="{ loading: submitStatus == 'PENDING' }"
      >
        <span>Зарегистрироваться</span>
        <img
          src="../../assets/images/icons/spinner.svg"
          class="loader"
          alt=""
        />
      </button>
      <div class="modal__another-option">
        <p>
          Уже зарегистрированы?
          <a href="#" class="_undeline-link _yellow" @click="showLogInModal()"
            >Войти</a
          >
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import inputText from "@/components/form/inputText.vue";
import checkBox from "@/components/form/checkBox.vue";
import VueRecaptcha from 'vue-recaptcha';

import {
  required,
  email,
  minLength,
  maxLength,
  sameAs,
} from "vuelidate/lib/validators";
const rus = (value) => /^[А-ЯЁа-яё ]+$/.test(value);

export default {
  name: "SignupForm",
  props: ["network"],
  data() {
    return {
      publicPath: process.env.BASE_URL,
      ready: false,
      email: null,
      first_name: null,
      x5id: null,
      recaptcha: null,
      // password: null,
      // repPassword: null,
      agree_rules: false,
      agree_pd: false,

      errorMessage: {
        email: null,
        first_name: null,
        x5id: null,
        recaptcha: null,
        // password: null,
        // repPassword: null,
        agree_rules: null,
        agree_pd: null,
      },

      submitStatus: null,
    };
  },

  validations: {
    email: { required, email },
    first_name: { required, rus },
    recaptcha: { required },
    x5id: { maxLength: maxLength(16) },
    agree_rules: {
      required,
      sameAs: sameAs(() => true),
    },
  },
  watch: {
    checkbox(newVal) {
      if (!newVal) {
        this.$store.commit("SET_AGREE", false);
      }
    },
  },
  methods: {
    errorReset() {
      this.$v.$reset();

      this.errorMessage = {
        email: null,
        first_name: null,
        x5id: null,
        recaptcha: null,
        // repPassword: null,
        // password: null,
        agree_rules: null,
        agree_pd: null,
      };
    },
    showRules() {
      if (!this.$store.getters.agree) {
        this.$modal.show("document_popup", {
          src: this.publicPath + "docs/rules.pdf",
        });
      }
    },
    validationStatus: function (validation) {
      return typeof validation != "undefined" ? validation.$error : false;
    },
    setFormErrors(e) {
      const errors = e.response.data;
      this.submitStatus = null;
      
      let fieldsError = null;

      for (let index = 0; index < errors.length; index++) {
        const element = errors[index];
        if (element.field in this.errorMessage) {
          this.errorMessage[element.field] = element.message;
          fieldsError = true;
        }
      }

      if (errors && !fieldsError) {
        this.$modal.show("common_error", {
          text: "Что-то пошло не так! " + errors[0].message,
        });
      }
      this.$refs.recaptcha.reset();
      this.recaptcha = null;
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$pendding || this.$v.$error) return;

      if (this.submitStatus !== "PENDING") {
        this.submitStatus = "PENDING";
        let click_id = localStorage.getItem("click_id") || null;
        const ya_id = this.$metrika?.getClientID()
          ? this.$metrika?.getClientID()
          : null;

        const formData = new FormData();

        formData.append("first_name", this.first_name);
        formData.append("email", this.email);
        formData.append("x5id", this.x5id || '');
        formData.append("ya_id", ya_id);
        formData.append("agree_rules", this.agree_rules ? "yes" : "no");
        formData.append("agree_pd", this.agree_pd ? "yes" : "no");
        formData.append("click_id", click_id);
        formData.append("recaptcha", this.recaptcha);
        const data = {
          fields: formData,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };
        
        this.$store
          .dispatch("SignUp", data)
          .then((r) => {
            
            this.setMetrika("main_registration");
            this.setWeborama(3);
            localStorage.removeItem("click_id");
            this.$store.commit("SET_TOKEN", r.auth_key);
            this.$store.dispatch("GetProfile").then((res) => {
              this.$root.$emit("onLogined");
              this.$modal.hide("signup");
              this.$modal.show("success_signup");
              this.$router.push("/profile");
            });
            // this.$modal.show("enter_code", { id: r.id });
            // this.$modal.show("success_signup", { email: this.email });
            // this.$store.commit("SET_TOKEN", r.data[0]);

            // this.$store.dispatch("GetProfile").then(() => {
            //   this.$router.push({ name: "Profile"});
            // });
          })
          .catch((e) => {
            console.log(e.response);
            if (e.response) {
              this.setFormErrors(e);
            }
          });
      }
    },
    showLogInModal() {
      this.$modal.hide("signup");
      this.$modal.show("authorization");
    },
    onVerify(response) {
      this.recaptcha = response;
    },

    onCaptchaExpired() {
      this.recaptcha = null;
    },
  },
  mounted() {
    // const t = this;
    // setTimeout(() => {
    //   if (window.smartCaptcha) {
    //     const container = document.getElementById('captcha-container2');

    //     const widgetId = window.smartCaptcha.render(container, {
    //         sitekey: 'ysc1_EaAuEe1mglXSelhMCG7174gVpIKnLv5oIlnHSdroe8ea6a9e',
    //     });
    //     return widgetId;
    // }
    // }, 1000);
  },
  components: {
    inputText,
    checkBox,
    VueRecaptcha,
  },
};
</script>
