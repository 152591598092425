<template>
  <div class="winners section" id="winners">
    <div class="container">
      <div class="winners__header">
        <h2 class="winners__title section-title _yellow">
          <span>Победители</span>
        </h2>
      </div>

      <div class="winners__content">
        <div class="winners__top">
          <date-picker
            lang="lang"
            v-model="date"
            placeholder="Дата"
            prefix-class="xmx"
            format="DD.MM.YYYY"
            @input="searchByDate"
            :disabled-date="disabledRange"
            valueType="YYYY-MM-DD"
          >
            <template #icon-calendar>
              <svg
                width="20"
                height="22"
                viewBox="0 0 20 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.09375 8.4043H18.9177"
                  stroke="black"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M14.4414 12.3096H14.4507"
                  stroke="black"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M10.0039 12.3096H10.0132"
                  stroke="black"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M5.55859 12.3096H5.56786"
                  stroke="black"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M14.4414 16.1963H14.4507"
                  stroke="black"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M10.0039 16.1963H10.0132"
                  stroke="black"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M5.55859 16.1963H5.56786"
                  stroke="black"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M14.043 1V4.29078"
                  stroke="black"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M5.96484 1V4.29078"
                  stroke="black"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M14.2383 2.57922H5.77096C2.83427 2.57922 1 4.21516 1 7.22225V16.2719C1 19.3263 2.83427 21 5.77096 21H14.229C17.175 21 19 19.3546 19 16.3475V7.22225C19.0092 4.21516 17.1842 2.57922 14.2383 2.57922Z"
                  stroke="black"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </template>
          </date-picker>
          <div class="winners__search">
            <form
              class="winners__search-form"
              v-on:submit.prevent="searchForm"
              :class="{
                error:
                  validationStatus($v.searchQuery) ||
                  this.errorMessage.searchQuery,
              }"
            >
              <input
                class="winners__search-field"
                type="email"
                name="term"
                v-model="searchQuery"
                @focus="errorReset()"
                placeholder="поиск по email"
              />
              <button type="submit" class="winners__search-button"></button>
              <div
                v-if="searchQuery"
                @click="formReset()"
                class="winners__search-clear"
              ></div>
              <div
                class="error-hint"
                v-if="
                  validationStatus($v.searchQuery) ||
                  this.errorMessage.searchQuery
                "
                v-html="
                  (!$v.searchQuery.required && $v.searchQuery.$error
                    ? 'Обязательное поле'
                    : !$v.searchQuery.email && $v.searchQuery.$error
                    ? 'Некорректный e-mail'
                    : '') || this.errorMessage.searchQuery
                "
              ></div>
            </form>
          </div>
          <Select
            class="winners__select"
            :options="[
              { id: 'day', label: 'денежные призы' },
              { id: 'week', label: 'еженедельные призы' },
            ]"
            placeholder="название приза"
            @input="prizeType = $event"
          />
        </div>
        <div class="winners__result">
          <Table :thead="fields" v-if="winners && winners.length">
            <template v-slot:tbody>
              <tr v-for="(item, index) in winners" :key="index">
                <td style="width: 25%">
                  {{ item.use_at }}
                </td>
                <td style="width: 30%">
                  {{ item.user_email }}
                </td>
                <td style="width: 45%">
                  <div class="d-flex aic">
                    <img
                      v-if="item.type"
                      :src="
                        require(`../assets/images/prizes/user-prizes/prize_${item.type}.png`)
                      "
                      class="prize-img"
                      alt=""
                    />
                    <span>{{ item.type_name }}</span>
                  </div>
                </td>
              </tr>
            </template>
            <template v-slot:mobile>
              <table class="winners-tab-mobile">
                <tr v-for="(item, index) in winners" :key="index">
                  <td>
                    <table class="table-mobile__inner">
                      <tr>
                        <td>Дата</td>
                        <td>
                          {{ item.use_at }}
                        </td>
                      </tr>
                      <tr>
                        <td>EMAIL</td>
                        <td style="width: 30%">
                          {{ item.user_email }}
                        </td>
                      </tr>
                      <tr>
                        <td>ПРИЗ</td>
                        <td>
                          {{ item.type_name }}
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>
            </template>
          </Table>
          <button
            v-if="pages.length > 1 && pages.length != page"
            @click="loadMore()"
            class="winners__show-more btn btn--bordered _white"
          >
            Показать ещё
          </button>
          <div class="no-results" v-if="!winners.length">
            <div class="no-results" v-if="noSearchResults">
              Ничего не найдено
            </div>
            <div v-else-if="activeItem === 3">
              Победитель проверяется и&nbsp;скоро будет опубликован
            </div>
            <div v-else>Розыгрыш еще не&nbsp;проводился</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Table from "@/components/Table.vue";
import Select from "@/components/form/inputSelect.vue";
import { required } from "vuelidate/lib/validators";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/ru";
export default {
  components: {
    Table,
    DatePicker,
    Select,
  },
  data: function () {
    return {
      date: null,
      prizeType: null,
      lang: {
        formatLocale: {
          firstDayOfWeek: 1,
        },
        monthBeforeYear: false,
      },

      page: 1,
      perPage: 1,
      pages: [],
      searchQuery: null,
      total: 0,
      noResults: false,
      noSearchResults: false,
      errorMessage: {
        date: null,
        searchQuery: null,
      },
      fields: [
        {
          text: "Дата розыгрыша",
          size: "25%",
        },
        {
          text: "e-mail",
          size: "30%",
        },
        {
          text: "Приз",
          size: "45%",
        },
      ],
      winners: [],
      showPaginate: true,
      activeItem: 1,
    };
  },
  validations: {
    searchQuery: { required },
  },
  methods: {
    disabledRange: function (date) {
      return date < new Date(2024, 7, 1) || date > new Date(2025, 0, 0);
    },
    errorReset() {
      this.$v.$reset();

      this.errorMessage = {
        searchQuery: null,
      };
    },
    validationStatus: function (validation) {
      return typeof validation != "undefined" ? validation.$error : false;
    },
    setPages(total) {
      this.pages = [];
      if (total) {
        for (let index = 1; index <= total; index++) {
          this.pages.push(index);
        }
      }
    },
    paginate() {
      let page = this.page;
      this.$store.dispatch("GetWinners", { page: page }).then((response) => {
        this.winners = response.items;
      });
    },
    getWinnersResult(val) {
      this.noSearchResults = false;
      this.$store
        .dispatch("GetWinners", { page: this.page })
        .then((response) => {
          this.winners = response.data.rows;
          if (this.winners.length < 1) {
            this.noResults = true;
          } else {
            this.noResults = false;
          }
        });
    },
    searchByDate() {
      this.page = 1;
      const o = {
        filter: {
          and: [
            {},
            {
              use_at: {
                gte: `${this.date} 00:00:00`,
                lte: `${this.date} 23:59:59`,
              },
            },
          ],
        },

        page: 1,
      };
      
      if (this.prizeType) {
        o.filter.prizeType = {
          eq: this.prizeType,
        };
      }
      if (this.searchQuery) {
        o.filter.category = {
            eq: this.searchQuery,
          };
      }
      this.$store.dispatch("GetWinners", o).then((response) => {
        this.total = response._meta.pageCount;
        this.winners = response.items;
        if (!this.winners.length) {
          this.noSearchResults = true;
          this.page = 1;
        } else {
          this.noSearchResults = false;
        }
      });
    },
    loadMore() {
      this.page++;
      const o = {
        page: this.page,
      };
      if (this.date) {
        o.filter = {
          and: [
            {},
            {
              use_at: {
                gte: `${this.date} 00:00:00`,
                lte: `${this.date} 23:59:59`,
              },
            },
          ],
        };
      }
      if (this.searchQuery) {
        o.filter = {
          user_email: {
            eq: this.searchQuery,
          },
        };
      }
      if (this.prizeType) {
        o.filter.category = {
            eq: this.prizeType,
          };
      }
      this.$store.dispatch("GetWinners", o).then((r) => {
        if (r) {
          r.items.forEach((element) => {
            console.log(element);

            this.winners.push(element);
          });
        }
      });
    },
    searchForm() {
      this.$v.$touch();
      if (this.$v.$pendding || this.$v.$error) return;
      this.page = 1;
      const o = {
        filter: {
          user_email: {
            eq: this.searchQuery,
          },
        },
        page: 1,
      };
      if (this.prizeType) {
        o.filter.category = {
            eq: this.prizeType,
          };
      }
      this.$store.dispatch("GetWinners", o).then((response) => {
        this.total = response._meta.pageCount;
        this.winners = response.items;
        if (!this.winners.length) {
          this.noSearchResults = true;
          this.$v.$reset();
          this.page = 1;
          this.searchQuery = null;
        } else {
          this.noSearchResults = false;
        }
      });
    },
    formReset() {
      this.$v.$reset();
      this.page = 1;
      this.searchQuery = null;
      this.noSearchResults = false;
      this.$store
        .dispatch("GetWinners", {
          page: this.page,
        })
        .then((response) => {
          this.total = response._meta.pageCount;
          this.winners = response.items;
        });
    },
    isActive(menuItem) {
      return this.activeItem === menuItem;
    },
    setActive(menuItem) {
      this.activeItem = menuItem;
    },
  },
  mounted() {},
  created() {
    try {
      this.$store.dispatch("GetWinners").then((response) => {
        this.total = response._meta.pageCount;
        this.winners = response.items;
        if (!this.winners.length) {
          this.noResults = true;
        } else {
          this.noResults = false;
        }
      });
    } catch (e) {
      console.log(e);
      this.noResults = true;
    }
  },
  watch: {
    winners() {
      this.setPages(this.total);
    },
    prizeType(val) {
      const o = {
        page: this.page,
        filter: {
          category: {
            eq: val
          }
        }
      };
      if (this.date) {
        o.filter.and = [
            {},
            {
              use_at: {
                gte: `${this.date} 00:00:00`,
                lte: `${this.date} 23:59:59`,
              },
            },
          ];
      }
      if (this.searchQuery) {
        o.filter.user_email = {
            eq: this.searchQuery,
          };
      }
      this.$store.dispatch("GetWinners", o).then((r) => {
        if (r) {
          this.total = r._meta.pageCount;
          this.winners = r.items;
          if (!this.winners.length) {
            this.noSearchResults = true;
            this.page = 1;
          } else {
            this.noSearchResults = false;
          }
        }
      });
    }
  },
  computed: {
    // displayedWinners() {
    //   return this.paginate(this.winners);
    // },
  },
};
</script>

<style lang="scss">
.winners {
  position: relative;
  display: flex;
  padding-top: rem(73px);
  padding-bottom: rem(0px);
  align-items: center;
  z-index: 1;
  background-size: cover;
  &__title {
    margin-bottom: rem(15px);
  }
  &__top {
    position: relative;
    display: flex;
    flex-direction: column;
    z-index: 10;
  }
  &__tabs {
    display: flex;
    flex-direction: column-reverse;
    &-nav {
      position: relative;
      height: rem(54px);
      padding: 0 rem(25px);
      margin-bottom: rem(11px);
      background: #ffb59d;
      color: rgba($red, 0.5);
      text-transform: uppercase;
      line-height: rem(54px);
      text-align: center;
      font-family: $medium;
      font-size: rem(14px);
      border-radius: rem(25px);
      cursor: pointer;
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 50%;
        display: block;
        width: 100%;
        height: 100%;
        transform: translateX(-50%);
        background: $pink;
        border-radius: 25px;
        opacity: 0;
        z-index: -1;
      }
      &._active {
        background: $pink;
        color: $red;
        &::before {
          opacity: 1;
        }
      }
    }
  }
  &__select {
    margin: 0;
    width: 100%;
  }
  &__header {
    display: flex;
    justify-content: center;
  }
  .container {
    position: relative;
    z-index: 1;
  }
  &__search-field {
    width: 100%;
    height: $field-height-sm;
    padding: rem(16px) rem(24px);
    font-size: $field-font-size-sm;
    background: #fff;
    border: $field-border;
    font-family: $medium;
    box-shadow: none;
    text-align: left;
    color: $field-color;
    border-radius: $field-border-radius-sm;
    outline: none;
    &::placeholder {
      color: $field-placeholder;
      font-size: $field-font-size-sm;
      text-transform: uppercase;
    }
  }
  &__search {
    width: 100%;
  }
  &__search-form {
    position: relative;
    width: 100%;
    max-width: 100%;
    margin: rem(0px) auto rem(15px);
  }
  &__search-button {
    position: absolute;
    top: 50%;
    right: rem(0px);
    transform: translateY(-50%);
    width: rem(43px);
    height: rem(43px);
    z-index: 1;
    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: url("../assets/images/icons/search.svg") no-repeat center;
      background-size: rem(20px);
    }
  }
  &__search-clear {
    position: absolute;
    top: 0;
    right: rem(45px);
    width: rem(15px);
    height: 100%;
    background: url("../assets/images/icons/close-black.svg") no-repeat center;
    background-size: 100%;
    cursor: pointer;
  }
  &__content {
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }
  &__result {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    flex: auto;
    height: 100%;
    font-family: $bold;
    font-size: rem(18px);
  }
  &__img {
    position: absolute;
    left: 0;
    bottom: rem(-120px);
    pointer-events: none;
    z-index: 10;
  }
  .no-results {
    width: 100%;
    border-radius: rem(20px);
    align-self: center;
    padding: rem(90px) 0;
    text-align: center;
  }
  &__show-more {
    margin: rem(30px) auto 0;
  }
  @media (orientation: landscape) {
    padding-bottom: 0;
    padding-top: rem(100px);
    .container {
      width: rem(1190px);
      padding-bottom: 0 !important;
    }
    &__content {
      position: relative;
    }
    &__top {
      flex-direction: row;
      justify-content: center;
      align-items: flex-start;
      margin-bottom: rem(32px);
      padding-top: 0;
    }
    &__title {
      margin-bottom: rem(35px) !important;
    }
    &__search-field {
      width: 100%;
      height: $field-height-lg;
      font-size: $field-font-size-lg;
      border-radius: $field-border-radius-lg;
      &::placeholder {
        font-size: $field-font-size-lg;
      }
    }
    &__result {
      width: rem(840px);
      min-height: rem(300px);
      margin: auto;
    }
    &__tabs {
      flex: auto;
      flex-direction: row;
      &-nav {
        width: rem(232px);
        flex-grow: 1;
        height: rem(54px);
        padding: 0 rem(20px);
        line-height: rem(56px);
        margin-bottom: 0;
        margin-right: rem(12px);
        font-size: rem(14px);
        border-radius: rem(8px);
        &::before {
          top: rem(-2px);
          left: rem(0px);
          width: calc(100% + #{rem(4px)});
          height: rem(90px);
          transform: translateX(0%);
          border-radius: 8px;
          border-bottom-right-radius: rem(0px);
          border-bottom-left-radius: rem(0px);
        }
        &:last-of-type {
          margin-right: 0;
        }
      }
    }
    &__select {
      width: rem(262px);
      margin-left: rem(20px);
    }
    &__search {
      width: auto;
      min-width: rem(310px);
      margin-left: rem(20px);
      &-form {
        margin: 0;
      }
      &-clear {
        right: rem(55px);
      }
    }
    &__search-button {
      right: rem(10px);
      &::after {
        background: url("../assets/images/icons/search.svg") no-repeat center;
        background-size: rem(22px);
      }
    }
    &-tab-desktop {
      display: block;
    }
    &-tab-mobile {
      display: none;
    }
    .no-results {
      max-width: 70%;
      margin: auto;
      font-size: rem(28px);
    }
  }
}
@keyframes eagle {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(50vw, rem(-400px));
  }
  100% {
    transform: translate(110vw, rem(-600px));
  }
}
@keyframes eagleReverse {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(-50vw, rem(-450px));
  }
  100% {
    transform: translate(-110vw, rem(-900px));
  }
}
@keyframes eagleRotate {
  0%,
  100% {
    transform: rotate(3deg);
  }
  50% {
    transform: rotate(-3deg);
  }
}
</style>
